import React, { useEffect, useState } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import AOS from 'aos';
import{ PhoneOutlined } from '@ant-design/icons';
import 'aos/dist/aos.css';

const Pic = ({path, ...props}) => {
  return (
    <picture {...props}>
      <source srcSet={`${path}.webp`} type="image/webp"/>
      <source srcSet={`${path}.png`} type="image/png"/> 
      <img alt="" src={`${path}.png`} data-aos='fade-up'/>
    </picture>
  );
}

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const scrollTo = (id) => {
    setOpenMenu(false);
    const top = document.getElementById(id).getBoundingClientRect().y;
    window.scrollTo({top: top + window.pageYOffset - 150, behavior: 'smooth'})
  }

  useEffect(() => {
    AOS.init({ duration : 1000 })
  }, []);
  return (
    <>
      <div className="header">
        <div className={`items ${openMenu ? 'open' : ''}`}>
          <div className="hamburger" onClick={() => setOpenMenu(!openMenu)}>
            <HamburgerMenu
              menuClicked={() => { }}
              isOpen={openMenu}
              width={18}
              height={15}
              strokeWidth={2}
              rotate={0}
              color='gray'
              borderRadius={0}
              animationDuration={0.3}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div className={'items-wrapper'}>
              <div className="item" onClick={() => scrollTo('root') }><img alt="logo" src='/logo.png' height="32" />שלטי אשדוד</div>
              <div className="item" onClick={() => scrollTo('products')}>מוצרים</div>
              <div className="item" onClick={() => scrollTo('about-us')}>אודות</div>
              <div className="item" onClick={() => scrollTo('contact')}>צור קשר</div>
            </div>
            <div className={'items-wrapper'}>
              <div className="item"><a rel={"noopener noreferrer"} target="_BLANK" href="https://goo.gl/maps/mDZHsN8DZuDiY9JK7">נווט אלינו</a></div>
              <div className="item"><a rel={"noopener noreferrer"} href="tel:+972526853073"><PhoneOutlined /> 052-685-3073</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-images">
        <Pic path='header-images/1' data-aos='fade-up' />
        <Pic path='header-images/2' data-aos='fade-up' data-aos-delay="100" />
        <Pic path='header-images/3' data-aos='fade-up' data-aos-delay="200" className='on-mobile' />
        <Pic path='header-images/4' data-aos='fade-up' data-aos-delay="300" />
        <Pic path='header-images/5' data-aos='fade-up' data-aos-delay="400" />
        <div className='white-overlay' data-aos='fade-up' data-aos-delay="800">
          <img alt="logo" src='/logo.png' height="100" />שלטי אשדוד
        </div>
      </div>
    </>
  )
}

export default Header;