import React, { useState } from 'react';
import * as firebase from 'firebase';
import "firebase/functions";
import './style.scss';
import{ PhoneOutlined } from '@ant-design/icons';

firebase.initializeApp({
  apiKey: 'AIzaSyB8-64LUZHl3d2GCLqdjCziAbTNCpNK3hc',
  authDomain: 'shelet-co-il.firebaseapp.com',
  projectId: 'shelet-co-il',
  databaseURL: 'https://shelet-co-il.firebaseio.com',
});

// Initialize Cloud Functions through Firebase
const contact = firebase.app().functions('europe-west3').httpsCallable('contact');

const LeaveAMessage = () => {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [sendStatus, setSendStatus] = useState(null);

  const submit = async (name, phone) => {
    if(name < 2 || phone < 4) return alert("אנא וודא שהשדות תקינים");
    if(sendStatus) return;
    try {
      setSendStatus('שולח...');
      await contact({test: 1,name, phone});
      setSendStatus('נשלח בהצלחה!');
    } catch (err) {
      alert(err.message);
      setSendStatus(null);
    }

  }

  return (
    <div className='LeaveAMessage'>
      <div style={{ width: '70px' }}>טלפון: </div><input value={phone} onChange={e => setPhone(e.target.value)} />
      <div style={{ width: '70px' }}>שם: </div><input value={name} onChange={e => setName(e.target.value)} />
      <div className='submit-button' onClick={() => submit(name, phone)} disabled={sendStatus}>{sendStatus || 'שלח'}</div>
    </div>
  )
}

const ContactRow = () => {
  return (
    <div className="contact-row" id="contact" data-aos='fade-up'>
      <div className="item">
        <h2>שעות פתיחה</h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <div>8:30 עד 17:00</div><div>ראשון עד חמישי</div>
            <div>8:30 עד 11:00</div><div>שישי</div>
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 10 }}>מומלץ להתקשר לפני הגעה</div>
          <a href="tel:+972526853073" style={{marginTop: '20px'}}><PhoneOutlined /> 052-6853073 - יוני</a>
        </div>
      </div>
      <div className="item">
        <a href="https://goo.gl/maps/iZ1jDneAF3HfGVVh7" target="_map"><img style={{maxWidth: '80vw'}} src='/map.png' alt='' /></a>
        <div style={{marginTop: '20px'}}>המסגר 2, אשדוד</div>
      </div>
      <div className="item">
        <h2>צור קשר</h2>
        <LeaveAMessage />
      </div>
    </div>
  )
}

export default ContactRow