import React, { useEffect, useState } from 'react';
// import HamburgerMenu from 'react-hamburger-menu';
import Header from './components/header';
import ContactRow from './components/contact-row';
import * as contentful from 'contentful';
import Product from './components/Product/Product';
import AboutUs from './components/AboutUs/AboutUs';
import { Image, message, Modal } from 'antd';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import 'antd/dist/antd.css';
import './App.scss';

const client = contentful.createClient({
  space: 'tuiqoemd1z3j',
  accessToken: 'Qe8T6QswwA_c3tNt4K1mEtG3ZoFT7yBIOIgJZjWY3DA',
});

function App() {
  const [products, setProducts] = React.useState([]);

  const fetchItems = async () => {
    const entries = await client.getEntries();
    setProducts(entries.items);
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <div className="App">
        <Header />
        <div className="content-wrapper">
          <div>

          <div className="video-wrapper" data-aos='fade-up'>
            <div className='desc'>
              <h2>הדפסה על מגוון חומרים, בטכנולוגיות שונות</h2>
              <p>דפוס מבוסס סולבנט על קנבס, מדבקות, טפט קיר, מגנט</p>
              <p>צבע על בסיס מים להדפסה ישירות על בד, טפט או דמוי-עור</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img alt="" src="/rollup.jpg" style={{maxHeight: '300px', marginLeft: '-60px', zIndex: 2, boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.8)'}} />
              <video autoPlay muted loop>
                <source src='/shelet2.webm' type="video/webm" />
                <source src='/shelet2.mp4' type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="video-wrapper" data-aos='fade-up'>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img alt="" src="/perspex.jpg" style={{maxHeight: '300px', maxWidth: "30%", marginLeft: '-60px', zIndex: 2, boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.8)'}} />
              <video autoPlay muted loop>
                <source src='/perspex.webm' type="video/webm" />
                <source src='/perspex.mp4' type="video/mp4" />
              </video>
            </div>
            <div className='desc'>
              <h2>הדפסה על חומר קשיח</h2>
              <p>דפוס ישיר על משטחים בצבע UV העמיד בשמש</p>
              <p>ניתן להדפיס על זכוכיות, משטחים אקרילים ועוד</p>
            </div>
          </div>
          <div data-aos='fade-up' className='content'>
            <h2 id="products">מוצרים</h2>
            <div className="products" data-aos='fade-up' data-aos-delay="400">
              {products.map(product => (
                <Product key={product.sys.id} product={product} />
              ))}
            </div>
          </div>
          <div data-aos='fade-up' className='about-us'>
            <AboutUs />
          </div>
          <ContactRow />
          <div className='footer'>
            <a href='tel://972526853073'>שלטי אשדוד - 052-6853073</a>
          </div>
        </div>
        <a href="https://wa.me/972526853073" target="_whatsapp">
          <svg className='whatsapp' xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12.031 6.172a5.773 5.773 0 00-5.768 5.766c-.001 1.298.38 2.27 1.019 3.287L6.7 17.353l2.182-.573c.978.58 1.911.928 3.145.929a5.776 5.776 0 005.768-5.766 5.764 5.764 0 00-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824a2.371 2.371 0 01-1.092-.069c-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446a.638.638 0 01.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.029 18.88a6.944 6.944 0 01-3.318-.844L5.034 19l.984-3.595a6.922 6.922 0 01-.926-3.468C5.093 8.112 8.205 5 12.029 5a6.891 6.891 0 014.907 2.034 6.893 6.893 0 012.03 4.908c-.001 3.825-3.113 6.938-6.937 6.938z"/></svg>
        </a>
          </div>
      </div>
      <Routes>
        <Route path="/product/:productId" element={<ProductModal products={products} />} />
      </Routes>
    </>
  );
}

export default App;

const ProductModal = ({products}) => {
  let { productId } = useParams();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  let navigate = useNavigate();
  
  if(!products?.length) return null;
  
  const product = products.find(product => product.sys.id === productId);
  
  if(!product) {
    message.error("מוצר לא נמצא");
    return null;
  }
  const title = product.fields.name;
  const description = documentToReactComponents(product.fields.description);
  
  const images = product.fields.images.map(image => image.fields.file.url);

  return (
      <Modal visible={true} onCancel={() => navigate('/')} footer={null} title={title}>
        <div style={{marginBottom: "30px"}}>
          {description}
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Image placeholder src={images[0]} onClick={() => setIsGalleryOpen(0)} preview={{ visible: false }} style={{maxHeight:"400px"}} />
        </div>
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup preview={{ visible: isGalleryOpen !== false, onVisibleChange: vis => setIsGalleryOpen(vis), current: isGalleryOpen }}>
            {images.map((img, key) => (
              <Image src={img} key={key} />
            ))}
          </Image.PreviewGroup>
        </div>
        <div style={{display: "flex", flexWrap: "wrap", gridGap: "10px", marginTop: '20px', alignItems: 'center', justifyContent: 'center'}}>
          {images.length > 1 && images.map((image, key) => (
            <Image placeholder src={image} key={key} onClick={() => setIsGalleryOpen(key )} preview={{ visible: false }} style={{maxHeight:"150px", maxWidth: '150px'}} />
          ))}
        </div>
      </Modal>
  )
}