import React from "react";
import { useNavigate } from "react-router-dom";

const Product = ({ product }) => {
  const imageUrl = product.fields.images[0].fields.file.url;
  let navigate = useNavigate();

  return (
    <div className="item" onClick={() => navigate(`/product/${product.sys.id}`)}>
      <span>{product.fields.name}</span>
      <img className="background" src={imageUrl} alt={product.fields.name} />
    </div>
  )
};

export default Product;