import React from "react";
import './style.scss';

const AboutUs = () => {
  return (
    <div id="about-us">
      <h1>קצת עלינו</h1>
      <div className="card">
        <p>אנחנו עסק משפחתי קטן, ממוקמים באשדוד
          <br />
          <span>זוג נשוי בשם קטי ויונתן, לנו ארבעה ילדים. אוהבים את המלאכה ודואגים לתת יחס משפחתי ואישי לכל לקוח ולקוח</span>
        </p>
        <p><b>בשלטי אשדוד</b> אנו מייצרים שלטים מאז 1988, אנו שמים דגש על מוצרים באיכות גבוהה, אחריות ואספקה מהירה של שילוט מכל הסוגים</p>

      </div>
    </div>
  )
};

export default AboutUs;